import { NgModule } from '@angular/core';
import {PagesRoutingModule} from "src/app/applications/pages/pages-routing.module";


@NgModule({
  declarations: [],
  imports: [
    PagesRoutingModule,
  ],
  providers:[]
})
export class PagesModule { }
