import {Injectable} from '@angular/core';
import {Apollo, gql, TypedDocumentNode} from "apollo-angular";
import {Observable} from "rxjs";
import {ApolloQueryResult, QueryOptions} from "@apollo/client/core";
import {QueryConstructorService} from "src/app/kernel/graphql/services/query-constructor.service";
import {EmptyObject, MutationOptions, MutationResult} from "apollo-angular/types";
import {HttpHeaders} from "@angular/common/http";



@Injectable({
  providedIn: 'root'
})

export class GraphService {

  constructor(
    private apollo: Apollo,
    private queryConstructor: QueryConstructorService
  ) {}


  /**
   * Wraps graphql query.
   * @param options
   */
  query<T>(options: QueryOptions): Observable<ApolloQueryResult<T>> {
    return this.apollo.query<T>(options);
  }

  /**
   * Wraps graphql mutation.
   * @param options
   */
  mutate<T, V = EmptyObject>(options: MutationOptions<T, V>): Observable<MutationResult<T>>{
    return this.apollo.mutate<T, V>(options);
  }


  /**
   * Constructs a query from arrayQuery then executes the query.
   * @param query
   * @param name
   * @param params
   */
  constructQuery<T>(
    query: string[],
    name = ``,
    params: any = null,
    headers?: HttpHeaders
  ): Observable<ApolloQueryResult<T>>
  {
    const options = <QueryOptions>{
      query: this.prepareQuery(this.queryConstructor.constructQuery(query, name, params, params?.__paramsDef),),
      variables: params,
    }
    if (headers) { options.context = { headers }; }
    return this.query<T>(options);
  }


  /**
   * Constructs a query from arrayQuery then executes the query.
   * @param query
   * @param name
   * @param params
   * @param paramsDef
   */
  constructListingQuery<T>(
    query: string[],
    name = ``,
    params: any = null,
    paramsDef: any = null,
    headers?: HttpHeaders
  ): Observable<ApolloQueryResult<T>> {
    const options = <QueryOptions>{
      query: this.prepareQuery(this.queryConstructor.constructQuery(
        [...query.map((i: string) => `data.${i}`), `pagination.total`],
        name,
        params,
        {
          ...params?.__paramsDef,
          ...paramsDef ?? {},
        },
      )),
      variables: params,
    }
    if (headers) { options.context = { headers }; }
    return this.query<T>(options);
  }

  /**
   * Constructs a watchquery from arrayQuery then executes the query.
   * @param query
   * @param name
   * @param params
   */
  constructWatchListQuery<T>(query: string[], name = ``, params: any = null, headers?: HttpHeaders) {
    const options = <QueryOptions>{
      query: this.prepareQuery(this.queryConstructor.constructQuery(
        [...query.map((i: string) => `data.${i}`), `pagination.total`],
        name,
        params,
        params.__paramsDef
      )),
      notifyOnNetworkStatusChange: false,
      variables: params,
    }
    if (headers) { options.context = { headers }; }
    return this.apollo.watchQuery<T>(options).valueChanges;
  }

  /**
   * Constructs a mutation then executes the query.
   * @param name
   * @param paramsDef
   * @param variables
   * @param query
   */
  constructMutation<T>(
    name: string,
    paramsDef: any,
    variables: object,
    query: string[],
    headers?: HttpHeaders
  ): Observable<MutationResult<T>> {
    const options = <MutationOptions>{
      mutation: gql(this.queryConstructor.constructMutation(name, paramsDef, query)),
      variables,
    }
    if (headers) { options.context = { headers }; }
    return this.mutate<T>(options);
  }

  /**
   * Converts string query to gql query.
   * @param query
   */
  prepareQuery(query: string): TypedDocumentNode {
    return gql(query);
  }


}
