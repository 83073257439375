import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: '',
    // loadChildren: () => import('./modules/home/home.module').then(({ HomeModule }) => HomeModule)
    redirectTo: 'dashboard/home',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    children: [
      {
        path: 'home',
        loadChildren: () => import('./modules/home/home.module').then(({ HomeModule }) => HomeModule)
      },
      {
        path: 'passengers',
        loadChildren: () => import('./modules/passengers/passengers.module').then(
          ({ PassengersModule }) => PassengersModule
        )
      },
      {
        path: 'employees',
        loadChildren: () => import('./modules/employees/employee.module').then(({ EmployeeModule }) => EmployeeModule)
      },
      {
        path:'addresses',
        loadChildren:()=>import('./modules/addresses/addresses.module').then(({AddressesModule})=>AddressesModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then(({ ProfileModule }) => ProfileModule)
      },
      {
        path: 'subscriptions',
        loadChildren: () => import('./modules/subscriptions/subscriptions.module')
        .then(({ SubscriptionsModule }) => SubscriptionsModule)
      },
      {
        path: 'payment-methods',
        loadChildren: () => import('./modules/payment-methods/payment-methods.module')
        .then(({ PaymentMethodsModule }) => PaymentMethodsModule)
      },
      {
        path: 'wallet-refunds',
        loadChildren: () => import('./modules/wallet-refunds/wallet-refunds.module')
        .then(({ WalletRefundsModule }) => WalletRefundsModule)
      },
      {
        path: 'transaction-receipts',
        loadChildren: () => import('./modules/transactions-receipts/transactions-receipts.module')
        .then(({ TransactionsReceiptsModule }) => TransactionsReceiptsModule)
      },
      {
        path: 'contracts',
        loadChildren: () => import('./modules/contracts/contracts.module')
        .then(({ ContractsModule }) => ContractsModule)
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
