import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from './dashboard-routing.module';


@NgModule({
  declarations: [],
  imports: [
    DashboardRoutingModule,
  ],
  providers:[]
})
export class DashboardModule { }
