import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appInfoStateReducers } from 'src/app/core/root-store/root.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GraphqlModule } from 'src/app/kernel/graphql/graphql.module';
import { environment } from 'src/environments/environment';
import { RootEffects } from 'src/app/core/root-store/root.effects';
import { PopupWrapperModule } from '../kernel/popups/popup-wrapper/popup-wrapper.module';
import { SpinnerModule } from '../kernel/shared/modules/spinner/spinner.module';
import { TranslationModule } from '../kernel/translations/modules/translation.module';
import { getCurrencySymbol } from '@angular/common';
import { NgxStripeModule } from 'ngx-stripe';

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    // ===================== Core Imports { =====================
    GraphqlModule.on(environment.graphql_endpoint),
    // ===================== } Core Imports =====================

    // =================== STATE MANAGEMENT { ===================
    StoreModule.forRoot({ appInfo: appInfoStateReducers }),
    EffectsModule.forRoot([RootEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
    }),
    // =================== } STATE MANAGEMENT ===================
    PopupWrapperModule,
    SpinnerModule,
    TranslationModule.forRoot('/assets/i18n/root/'),
    NgxStripeModule.forRoot(environment.STRIP_KEY)
  ],
  providers: [],
  exports: [ PopupWrapperModule, SpinnerModule],
  bootstrap: [],
})
export class CoreModule {
  constructor() {
   
  }
}