import { DOCUMENT } from "@angular/common"
import { inject } from "@angular/core"
import { environment } from "src/environments/environment";

export const loadGoogleScript = () => {
  createScript(`${environment.GOOGLE_SCRIPT}?key=${environment.google_api_key}&loading=async`);
  return () => Promise.resolve(true);
}

export const loadGoogleClusterScript = () => {
  createScript('https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js')
  return () => Promise.resolve(true);
}

export const createScript = (src: string) => {
  const document = inject(DOCUMENT);
  const script = document.createElement('script');
  script.src = src;
  script.defer = true;
  script.async = true;
  document.body.appendChild(script);
}