import AuthUserModelInterface from 'src/app/kernel/shared/interfaces/auth-user-model.interface';
import {IEmployeeOrigin, IRoleType, IUser} from '../interfaces';

export class AuthUserModel implements AuthUserModelInterface {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public image: string,
    public permissions: string[],
    public roles: IRoleType[],
    public employeeOrigin?: IEmployeeOrigin
  ) {}

  static create(data: IUser): AuthUserModel {
    const permissions = (data.roles as IRoleType[]).flatMap((role) => role.permissions);
    const {
      id,
      email,
      personalInfo,
      roles,
      employeeOrigin
    } = data;
    const { name, surename, profilePicture} = personalInfo || {name:'',surename:'', profilePicture:'default.png'};

    return new AuthUserModel(
      id,
      `${name} ${surename}`,
      email,
      profilePicture,
      permissions,
      roles as IRoleType[],
      employeeOrigin
    );
  }

  public can(permission: string | (string | string[])[]): boolean {
    if (!permission) {
      return true;
    }
    if (this.permissions.includes('SUPER_ADMIN')) {
      return true;
    }
    if (typeof permission === 'string') {
      permission = [permission];
    }
    return this.doPermissionsCheck(this.permissions, permission);
  }

  /**
   * does an or check on permissions, taking and check in consideration.
   * @param owned
   * @param required
   */
  private doPermissionsCheck(
    owned: string[],
    required: (string | string[])[]
  ): boolean {
    if (!required || !required.length) {
      return true;
    }
    return required.some((_permission) =>
      typeof _permission === 'object'
        ? this.doAndPermissionsCheck(owned, <string[]>_permission)
        : owned.indexOf(<string>_permission) !== -1
    );
  }

  /**
   * does an and condition on permissions.
   * @param owned
   * @param required
   */
  private doAndPermissionsCheck(owned: string[], required: string[]): boolean {
    return required.every((_permission) => owned.includes(_permission));
  }
}
