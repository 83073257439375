import { registerLocaleData } from '@angular/common';
import { inject } from '@angular/core';
import { LocalStorageService } from 'src/app/kernel/tools/services/local-storage.service';

const locales = {
  'en': () => import('@angular/common/locales/en'),
  'ar': () => import('@angular/common/locales/ar'),
}

export const loadLocale = () => {
  const localStorage = inject(LocalStorageService);
  const lang = localStorage.get<'en' | 'ar'>('lang') || 'en';
  locales[lang]().then((d) => registerLocaleData(d.default));
  return lang;
}