import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/company-auth/company-auth.module').then(
        (m) => m.CompanyAuthModule
      ),
  },
  {
    path: 'employee-auth',
    loadChildren: () =>
      import('./modules/employee-on-boarding/employee-on-boarding.module').then(
        (m) => m.EmployeeOnBoardingModule
      ),
  },
  {
    path: 'passenger-auth',
    loadChildren: () =>
      import('./modules/passenger-on-boarding/passenger-on-boarding.module').then(
        (m) => m.PassengerOnBoardingModule
      ),
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
