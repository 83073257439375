import { NgModule } from '@angular/core';
import {AuthenticationRoutingModule} from "src/app/applications/authentication/authentication-routing.module";

@NgModule({
  declarations: [],
  imports: [
    AuthenticationRoutingModule,
  ],
  providers:[]
})
export class AuthenticationModule { }
