import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';

const routes: Route[] = [
  {
    path: 'privacy',
    loadChildren: () => import('./modules/privacy/privacy.module').then(({ PrivacyModule }) => PrivacyModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./modules/terms/terms.module').then(({ TermsModule }) => TermsModule)
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
