export const environment = {
  firebase: {
    projectId: 'rentalab-6b6aa',
    appId: '1:749218194590:web:dce243b6168037626f078f',
    storageBucket: 'rentalab-6b6aa.appspot.com',
    apiKey: 'AIzaSyCnnldU1Oz7S8k3osD8u4IqxN-UB_Q_3g4',
    authDomain: 'rentalab-6b6aa.firebaseapp.com',
    messagingSenderId: '749218194590',
    measurementId: 'G-GX91B9F13V',
  },
  google_api_key: 'AIzaSyBWLWsLeLXb1X7rgxwhBaxl6UnCBykjl60',
  routes_api_key: 'AIzaSyDeJ5xM143_LxsyxSacy36EVeBALiaRbdY',
  production: true,
  version: '0.0.1 (beta)',
  graphql_endpoint: 'https://graph.vogo.storexweb.team/graphql',
  url: 'https://files.vogo.storexweb.team/',
  places_key: 'AIzaSyAxFKLWDZmhVY2lL5dIUwnflxmeE7ji33s',
  skip_auth: false,
  COMPUTED_ROUTE_MATRIX: 'https://routes.googleapis.com/distanceMatrix/v2:computeRouteMatrix',
  GOOGLE_SCRIPT: 'https://maps.googleapis.com/maps/api/js',
  passengerAndroidApp:'intent://profile/4#Intent;package=com.facebook.katana;scheme=fb;end',
  passengerIOSApp:'intent://profile/4#Intent;package=com.facebook.katana;scheme=fb;end',
  TIME_IN_MINUTES: 10,
  STRIP_KEY:"pk_test_s6cToNHgpsPrcwPPnHu5Zevq00VN5oUuax",
  stripReturnUrl: 'http://company.vogo.storexweb.team/dashboard',

};
